import { Outlet } from "react-router-dom"


function AuthenticationLayout() {
  return (
    <Outlet/>
  )
}

export default AuthenticationLayout
