import React from 'react'

function LSContainer({children}) {
  return (
    <div className="main-container">
         {children}
        </div>
  )
}

export default LSContainer