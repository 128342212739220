import React from 'react'

function Footer() {
  return (
    <div className='footer'>
      @Copyright 2023 | Loric Softwares 
    </div>
  )
}

export default Footer